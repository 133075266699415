import { useState } from "react";
import { useQueryClient } from "react-query";
import { v4 as v4ID } from "uuid";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDashboardContext } from "contexts/DashboardContext";
import { useSaveFilterPreset } from "api";

const CreateFilterPreset = () => {
  const [open, setOpenDialog] = useState(false);
  const [presetName, setPresetName] = useState("");
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { isInvalidFilter, filterPresetModel } = useDashboardContext();

  const { mutate: saveFilters, isLoading } = useSaveFilterPreset({
    onMutate: async (filterPreset: FilterPreset[]) => {
      await queryClient.cancelQueries("filter-presets");

      const previousFilterPresetsData =
        queryClient.getQueriesData("filter-presets");

      queryClient.setQueryData("filter-presets", (oldQueryData: any) => {
        oldQueryData.push({
          id: v4ID(),
          ...filterPreset,
        });

        return oldQueryData;
      });

      return previousFilterPresetsData;
    },
    onError: (_error: any, _filterPreset: any, context: any) => {
      queryClient.setQueryData(
        "filter-presets",
        context.previousFilterPresetsData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("filter-presets");
      setPresetName("");
      setError("");
      setOpenDialog(false);
    },
  });

  const handleClickSave = async () => {

    if (!presetName) {
      setError("Preset name is required.");
      return;
    } 

    if (isInvalidFilter) {
      setError("No filters selected or missing value on filters!");
      return;
    }
    
    if (!isInvalidFilter) {
      const payload = {
        name: presetName,
        filters: filterPresetModel.items,
      };

      saveFilters(payload);
    }
  };

    const handleClose = () => {
      setOpenDialog(false)
      setError("");
    };
  return (
    <>
      <ListItemButton onClick={() => setOpenDialog(true)}>
        <ListItemText primary="Create Preset" />
      </ListItemButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          {"Create New Filter Preset"}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="standard-basic"
            label="Preset name"
            variant="standard"
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            helperText={error}
            error={error !== ""}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            onClick={handleClickSave}
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateFilterPreset;
