import { useState, ChangeEvent } from 'react';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CurrencyFormat from 'react-currency-format';

import { currencyFormatter } from "utils/currencyFormatter";
import { useSetDailyBudget } from "api/adManagement";
import { useQueryClient } from "react-query";
import { AdAccounts, Adset, Campaign } from "types";
import { useNotificationContext } from 'contexts/Notification';

export const BudgetCell = ({ cell }: any) => {
  const { row, rowNode } = cell;
  const queryClient = useQueryClient()

  const { setNotification } = useNotificationContext()
  const [isEditing, setIsEditing] = useState(false);
  const [budget, setBudget] = useState(row?.daily_budget || 0);

  const { mutate: updateBudget } = useSetDailyBudget({
    onError: ({ error, data, context }) => {
      queryClient.setQueryData(
        "ad-account-insights",
        context.previousInsightsData
      );
    },
    onSettled: () => {
      setIsEditing(false);
      queryClient.invalidateQueries("ad-account-insights");
      setNotification({
        open: true,
        message: "Budget updated successfully."
      })
    },
    onMutate: async ({
      campaignId,
      adSetId,
      dailyBudget,
    }: {
      campaignId?: string;
      adSetId?: string;
      dailyBudget: number;
    }) => {
      await queryClient.cancelQueries("ad-account-insights");
      const previousInsightsData = queryClient.getQueriesData(
        "ad-account-insights"
      );

      queryClient.setQueriesData(
        "ad-account-insights",
        (oldInsightsData: any) => {
          return {
            ...oldInsightsData,
            data: oldInsightsData?.data?.map((account: AdAccounts) => {
              return {
                ...account,
                campaigns: account.campaigns?.map((campaign: Campaign) => {
                  if (adSetId) {
                    return {
                      ...campaign,
                      adsets: campaign.adsets?.map((adset: Adset) => {
                        if (adset.id === adSetId) {
                          return {
                            ...adset,
                            daily_budget: dailyBudget,
                          };
                        }

                        return adset;
                      }),
                    };
                  }

                  if (campaignId && campaign.id === campaignId) {
                    return {
                      ...campaign,
                      daily_budget: dailyBudget,
                    };
                  }

                  return campaign;
                }),
              };
            }),
          };
        }
      );

      return {
        previousInsightsData,
      };
    },
  });

  const handleBudgetChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBudget(event.target.value?.replace(',', '') ?? 0);
  }

  const handleUpdateBudget = () => {
    const [, campaignId, adSetId,] = row.path;

    updateBudget({
      campaignId,
      adSetId,
      dailyBudget: parseFloat(budget)
    })
  }

  /**
   * This check is to determine whether or not the cell is at the campaign
   * or ad set level. `rowNode.depth` refers to the expanded level, and anything
   * greater than 0 is either a campaign (depth === 1) or ad set (depth === 2)
   */

  if (rowNode.depth === 0) {
    return (
      <>{currencyFormatter.format(row.daily_budget)}</>
    )
  }

  if (rowNode.depth > 2 && !row.daily_budget) {
    return (
      <>-</>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {
          isEditing
            ? (
              <>
                <CurrencyFormat
                  thousandSeparator
                  decimalScale={2}
                  customInput={TextField}
                  value={budget}
                  onChange={handleBudgetChange}
                  {...{
                    variant: 'standard' as any,
                    size: 'small' as any,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            mr: 0.5,
                            '> p': {
                              fontSize: '0.9rem'
                            }
                          }}
                        >
                          $
                        </InputAdornment>
                      )
                    },
                    sx: {
                      'MuiInputBase-root *': {
                        fontSize: '0.9rem',
                      },
                      'input': {
                        fontSize: '0.9rem',
                        paddingBottom: 0.1,
                      }
                    }
                  }}
                />
                <IconButton
                  aria-label="edit cpr target"
                  size="small"
                  onClick={handleUpdateBudget}
                >
                  <CheckRoundedIcon color="success" fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="edit cpr target"
                  size="small"
                  onClick={() => setIsEditing(false)}
                >
                  <CloseRoundedIcon color="error" fontSize="inherit" />
                </IconButton>
              </>
            ) : (
              <>
                <Typography variant="body2" lineHeight={1}>
                  {
                    rowNode.depth === 1 && parseFloat(row.daily_budget) === 0
                      ? 'Adset budget'
                      : !row.daily_budget
                        ? '-'
                        : currencyFormatter.format(row.daily_budget)
                  }
                </Typography>
                <IconButton
                  aria-label="edit cpr target"
                  size="small"
                  onClick={() => setIsEditing(true)}
                  sx={{ ml: 1 }}
                >
                  <EditRoundedIcon fontSize="inherit" />
                </IconButton>
              </>
            )
        }
      </Box>
    </>
  )
}