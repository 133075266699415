import { Alert, AlertColor, Snackbar } from "@mui/material"
import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react"

interface NotificationProps {
  open: boolean
  message: string
  severity?: AlertColor
}

interface NotificationContextProps {
  notification: NotificationProps
  setNotification: Dispatch<SetStateAction<NotificationProps>>
}

const NotificationContext = createContext<NotificationContextProps>({
  notification: {
    open: false,
    message: '',
    severity: "success"
  },
  setNotification: () => { }
})

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<NotificationProps>({
    open: false,
    message: '',
    severity: "success"
  })

  const handleClose = () => {
    setNotification(prev => ({...prev, open: false}))
  }

  return <NotificationContext.Provider value={{
    notification,
    setNotification
  }}>
    {children}
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={notification.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={notification.severity ?? "success"} onClose={handleClose} sx={{ alignItems: "center" }}>
        {notification.message}
      </Alert>
    </Snackbar>
  </NotificationContext.Provider>
}

export const useNotificationContext = () => useContext(NotificationContext)