import React from "react";
import { FilterPresetCreate } from "./FilterPresetCreate";
import { FilterPresetUpdate } from "./FilterPresetUpdate";
import { useDashboardContext } from "contexts/DashboardContext";

const ColumnPresetActions = () => {
  const { filterPresetModel: { items } } = useDashboardContext()
  return (
    <>
      {items && items.length > 0 && (
        <>
          <FilterPresetCreate />
          <FilterPresetUpdate />
        </>
      )}
    </>
  );
};

export default ColumnPresetActions;
