import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import type { SnackbarOrigin } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { Email, Password, Person } from '@mui/icons-material'
import { useSignIn } from "react-auth-kit";
import { useSignUpMutation } from 'api/auth'
import { ErrorBoundary } from 'components/ErrorBoundary';
import Button from '@mui/material/Button'
import type { Errors } from 'types';

export const Signup = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: ''
  })

  const { mutateAsync, isLoading, isSuccess } = useSignUpMutation({
    onSuccess: (data: any): any => {
      setErrors({})
      setInput({
        name: "",
        email: "",
        password: "",
        password_confirmation: ""
      })

    },
    onError: (error: any) => setErrors(error.response.data.errors)
  })

  const [errors, setErrors] = useState({} as Errors)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setInput({
      ...input,
      [event.target.name]: event.target.value
    })
  }

  const redirect = () => {
    navigate('/login')
  }

  const handleSubmit = (): void => {
    mutateAsync(input)
  }

  return (
    <ErrorBoundary fallback={<p>Something went wrong.</p>}>
      <Box height="100vh" className="bg-brand/10">
        <Box
          width='100%'
          height='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          component="form"
        >
          <img alt="smart ass metrics logo" src="/assets/images/logo.png" className='h-[200px] w-[200px]' />
          <Paper elevation={5} sx={{ py: 7, width: '50%', mb: 2, maxWidth: 650, minWidth: 400 }}>
            <Stack display="flex" spacing={3} height="100%" paddingX={7} textAlign="center">
              {
                isSuccess
                  ? (
                    <>
                      <h1 className='text-4xl'>Success!</h1>
                      <p className='text-2xl'>We have sent an email verification to your email. Kindly check.</p>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={redirect}
                      >
                        Click here to go to login page
                      </Button>
                    </>
                  )
                  : (
                    <>
                      <Box>
                        <h1 className='text-3xl font-extrabold'>Create An Account</h1>
                        <p>Already signed up? <Link onClick={redirect} className="cursor-pointer">Login</Link></p>
                      </Box>
                      <TextField
                        error={errors.hasOwnProperty('name')}
                        helperText={errors.name ? errors.name[0] : ''}
                        label="Name"
                        name="name"
                        variant="outlined"
                        value={input.name}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><Person /></InputAdornment>,
                        }}
                      />

                      <TextField
                        error={errors.hasOwnProperty('email')}
                        helperText={errors.email ? errors.email[0] : ''}
                        type="email"
                        label="Email"
                        name="email"
                        variant="outlined"
                        value={input.email}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><Email /></InputAdornment>,
                        }} />

                      <TextField
                        error={errors.hasOwnProperty('password')}
                        helperText={errors.password ? errors.password[0] : ''}
                        type="password"
                        label="Password"
                        name="password"
                        variant="outlined"
                        value={input.password}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><Password /></InputAdornment>,
                        }} />

                      <TextField
                        error={errors.hasOwnProperty('password')}
                        helperText={errors.password ? errors.password[0] : ''}
                        type="password"
                        label="Confirm Password"
                        name="password_confirmation"
                        variant="outlined"
                        value={input.password_confirmation}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><Password /></InputAdornment>,
                        }} />

                      <LoadingButton
                        type="submit"
                        loading={isLoading}
                        variant="contained"
                        sx={{ paddingY: 2 }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </LoadingButton>
                      <p>Or continue with <Link onClick={redirect} className="cursor-pointer">Facebook</Link></p>
                    </>
                  )
              }
            </Stack>
          </Paper>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
