import { useQuery, useMutation } from "react-query";
import axios from "axios";

import type { AdAccounts } from "types";

export const useSelectedAdAccountQuery = () => {
  return useQuery<{ data: AdAccounts[] }>({
    queryKey: "selected-ad-accounts",
    staleTime: 5 * 60 * 1000,
    queryFn: async () => {
      const response = await axios.get(`/api/ad-accounts/selected`);

      if (response.status !== 200) {
        throw new Error("Unable to fetch seelcted ad accounts");
      }

      return response.data;
    },
  });
};

export const generateTracking = async (accountId: string, domains: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TRACKING_APP_URL}/api/lead/generateTracking`,
      {
        add_acc_id: accountId,
        domains: domains.split(",").map((domain) => domain.trim()),
      }
    );
    return response.data.data; // Return the relevant data from the response
  } catch (error) {
    throw error;
  }
};

export const getDomains = async (accountId: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRACKING_APP_URL}/api/lead/domains`,
      {
        params: {
          add_acc_id: accountId,
        },
      }
    );
    return response.data.data; // Return the relevant data from the response
  } catch (error) {
    throw error;
  }
};
