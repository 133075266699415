import React, { useEffect } from 'react'
import { ErrorBoundary } from 'components/ErrorBoundary';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSignIn } from "react-auth-kit";
import { useValidateEmailVerificationTokenMutation } from 'api/auth'

export const EmailVerification = () => {
  const navigate = useNavigate()
  const signIn = useSignIn();
  const [searchParams] = useSearchParams()

  const { mutateAsync: validateEmailVerificationToken, isLoading } = useValidateEmailVerificationTokenMutation({
    onSuccess: (data) => {
      signIn({
        token: data.data.token,
        expiresIn: 60,
        tokenType: 'Bearer',
        authState: { email: data.data.email }
      })
    },
    onError: (error: any) => navigate('/login')
  })

  useEffect(() => {
    const token = searchParams.get('token') ?? ''
    validateEmailVerificationToken(token)
  }, [])

  return (
    <ErrorBoundary fallback={<p>Something went wrong.</p>}>
      <Box height="100vh" className="bg-brand/10">
        <Box
          width='100%'
          height='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          component='form'
        >
          <img alt="smart ass metrics logo" src="/assets/images/logo.png" className='h-[200px] w-[200px]' />
          <Paper elevation={5} sx={{ py: 7, width: '50%', mb: 2, maxWidth: 650, minWidth: 400 }}>
            <Stack display="flex" spacing={3} height="100%" paddingX={7} textAlign="center">
              {
                (isLoading)
                  ? <h1 className='text-4xl'>Loading...</h1>
                  : <h1 className='text-4xl'>Email verified</h1>
              }
            </Stack>
          </Paper>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}

