import { useNavigate } from "react-router-dom";
import {
  useIntegrateAccount,
  useLoginViaFacebook,
  useSyncAccounts,
} from "../api/integrateAccount";
import ReactFacebookLogin from "@greatsumini/react-facebook-login";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Facebook as FacebookIcon } from "@mui/icons-material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkIcon from "@mui/icons-material/Link";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { LoadingButton } from "@mui/lab";
import { useCurrentUser } from "contexts/CurrentUser";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { useNotificationContext } from "contexts/Notification";

const APP_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID ?? "";

export const FacebookLogin = ({
  iconOnly,
  textOnly,
  redirect,
  showFacebookIndicator = false,
  setLoadingNextButton,
}: {
  iconOnly?: boolean;
  textOnly?: boolean;
  redirect?: string;
  showFacebookIndicator?: boolean;
  setLoadingNextButton?: any;
}) => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const scopes =
    "public_profile, email, read_insights, pages_show_list, ads_management, ads_read, business_management";

  const { setNotification } = useNotificationContext()
  const { mutate: login, isLoading: isLoginViaFacebookLoading } =
    useLoginViaFacebook({
      onSuccess: (data: any): any => {
        if (redirect) {
          signIn({
            token: data.data.token,
            expiresIn: 60,
            tokenType: "Bearer",
            authState: data.data.user,
          });

          navigate(redirect);
        }
      },
    });

  const { mutate: integrate, isLoading } = useIntegrateAccount({
    onSuccess: (data: any): any => {
      setLoadingNextButton(false);
    },
  });

  const { mutate: syncAdAccounts, isLoading: syncingAdAccounts } =
    useSyncAccounts({
      onSuccess: () => {
        if (!textOnly) {
          setLoadingNextButton(false);
        }

        setNotification(prev => ({...prev, open: true, message: "Synced successfully", severity: "success"}))
      },
    });

  const onSuccess = (response: any) => {
    let responseData: any = {
      ...response,
      provider: "facebook",
    };

    FacebookLoginClient.getProfile(
      (profile) => {
        responseData = {
          ...responseData,
          profile,
        };

        if (isAuthenticated()) {
          setLoadingNextButton(true);
          integrate(responseData);

          return;
        }

        login(responseData);
      },
      {
        fields: "id,name,picture",
      }
    );
  };

  const handleSyncAccounts = () => {
    if (!textOnly) {
      setLoadingNextButton(true);
    }

    syncAdAccounts();
  };

  return (
    <>
      {currentUser?.facebook_account ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showFacebookIndicator && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="smart ass metrics logo"
                  src="/assets/images/logo.png"
                  className="h-[80px] w-[80px]"
                />
                <LinkIcon color="primary" sx={{ fontSize: 30 }} />
                <FacebookIcon
                  color="primary"
                  sx={{ fontSize: 50, marginLeft: "15px" }}
                />
              </Box>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                This account is already connected to Facebook.
              </Typography>
            </>
          )}

          <LoadingButton
            variant="contained"
            startIcon={<FacebookRoundedIcon />}
            onClick={handleSyncAccounts}
            loading={syncingAdAccounts}
          >
            Sync Accounts
          </LoadingButton>
        </Box>
      ) : (
        <>
          <ReactFacebookLogin
            appId={APP_ID}
            onSuccess={(response) => onSuccess(response)}
            onFail={(error) => {
              console.log("Login Failed!", error);
            }}
            scope={scopes}
            render={({ onClick }) => {
              if (isLoginViaFacebookLoading) {
                return <CircularProgress size={30} />;
              }

              if (iconOnly) {
                return (
                  <IconButton
                    aria-label="facebook"
                    size="large"
                    onClick={onClick}
                  >
                    <FacebookIcon />
                  </IconButton>
                );
              }

              if (textOnly) {
                return (
                  <Button variant="outlined" size="small" onClick={onClick}>
                    Integrate
                  </Button>
                );
              }

              return (
                <LoadingButton
                  variant="contained"
                  startIcon={<FacebookRoundedIcon />}
                  onClick={onClick}
                  loading={isLoading}
                >
                  Integrate with Facebook
                </LoadingButton>
              );
            }}
          />
        </>
      )}
    </>
  );
};
