import { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import GradeIcon from '@mui/icons-material/Grade';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { LoadingButton } from '@mui/lab'

import { useOnboardingContext } from 'contexts/OnboardingContext';
import { Welcome } from 'components/onboarding/Welcome';
import { AccountSelection } from 'components/settings/AccountSelection';
import { SetAccountLevelCpr } from 'components/settings/SetAccountLevelCpr';
import { OnboardingComplete } from 'components/onboarding/OnboardingComplete';
import { StepIconRoot, StepIconConnector } from 'components/onboarding/StepIcon';
import { FacebookLogin } from 'components/FacebookLogin';
import { useCurrentUser } from 'contexts/CurrentUser';

import { useSetOnboarding, useSetBulkCprAndThreshold } from 'api';
import { useQueryClient } from 'react-query';

interface OnboardingDialogProps {
  open: boolean;
  onClose: () => void;
}

enum OnboardingSteps {
  Welcome,
  Integrate,
  SelectAdAccount,
  SetCpr,
  Complete
}

const steps = [
  {
    id: OnboardingSteps.Welcome,
    label: 'Welcome',
    Component: Welcome
  },
  {
    id: OnboardingSteps.Integrate,
    label: 'Integrate with Facebook',
    Component: FacebookLogin
  },
  {
    id: OnboardingSteps.SelectAdAccount,
    label: 'Select Ad Accounts',
    Component: AccountSelection
  },
  {
    id: OnboardingSteps.SetCpr,
    label: 'Set Account-Level CPRs',
    Component: SetAccountLevelCpr
  },
  {
    id: OnboardingSteps.Complete,
    label: 'All Done!',
    Component: OnboardingComplete
  }
];

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <GradeIcon className="h-5 w-5" />,
    2: <FacebookRoundedIcon className="h-5 w-5" />,
    3: <CheckBoxIcon className="h-5 w-5" />,
    4: <TrackChangesIcon className="h-5 w-5" />,
    5: <RocketLaunchIcon className="h-5 w-5" />,
  };

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </StepIconRoot>
  );
}

export const Onboarding = ({
  open = false,
  onClose
}: OnboardingDialogProps) => {
  const { mutate: setOnboardingStatus, isLoading } = useSetOnboarding({
    onSuccess: () => {
      if (steps[currentStep].id !== OnboardingSteps.SetCpr) {
        setCurrentStep(prevStep => (prevStep + 1) % steps.length)
      }
    }
  });
  const { mutate: bulkUpdateCprAndThreshold } = useSetBulkCprAndThreshold({
    onSuccess: () => {
      setIsLoadingButton(false);
      setCurrentStep(prevStep => (prevStep + 1) % steps.length)
      queryClient.invalidateQueries('me')
    }
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    currentStep,
    setCurrentStep,
    selectedAccounts,
    globalCpr,
    globalThreshold,
    accountLevelCprOverrides
  } = useOnboardingContext();
  const isLastStep = currentStep === steps.length - 1;
  const [isloadingButton, setIsLoadingButton] = useState(false);
  const { currentUser } = useCurrentUser();
  const queryClient = useQueryClient()

  const handlePreviousClick = () => {
    setCurrentStep(prevStep => prevStep - 1);
  }

  const handleNextClick = () => {
    switch (steps[currentStep].id) {
      case (OnboardingSteps.SelectAdAccount):
        setOnboardingStatus({ isComplete: false, currentStep });
        break

      case (OnboardingSteps.SetCpr):
        const payload = selectedAccounts.map(({ id }) => {
          const account = accountLevelCprOverrides[id];

          if (account) {
            const { target, threshold } = account;

            return {
              id,
              target: parseFloat(target ?? globalCpr),
              threshold: parseInt(threshold ?? globalThreshold)
            }
          }

          return {
            id,
            target: parseFloat(globalCpr),
            threshold: parseInt(globalThreshold)
          }
        })

        setIsLoadingButton(true);
        bulkUpdateCprAndThreshold({
          id_type: 'ad_accounts',
          payload
        })
        setOnboardingStatus({ isComplete: false, currentStep });
        break

      case (OnboardingSteps.Complete):
        setOnboardingStatus({
          isComplete: true,
          currentStep,
          onSuccess: () => {
            searchParams.delete('onboarding');
            searchParams.delete('onboarding_step');
            setSearchParams(searchParams);
            setCurrentStep(0);
          }
        })
        break
      default:
        setOnboardingStatus({ isComplete: false, currentStep });
    }
    if (isLastStep) {
      searchParams.delete('onboarding');
      searchParams.delete('onboarding_step');
      setSearchParams(searchParams);
    }

    // setCurrentStep(prevStep => (prevStep + 1) % steps.length)
  }

  const disabledNextButton = (): boolean => {
    if (steps[currentStep].id !== OnboardingSteps.SelectAdAccount) return false

    if (selectedAccounts.length !== 0) return false

    return true
  }

  const handleCancelClick = () => {
    searchParams.delete('onboarding');
    searchParams.delete('onboarding_step');
    setSearchParams(searchParams);
  }

  return (
    <Dialog
      open={open}
      // onClose={ onClose }
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh'
        }
      }}
    >
      <DialogTitle>
        <Stepper
          alternativeLabel
          activeStep={currentStep}
          connector={<StepIconConnector />}
        >
          {steps.map(({ label }) => (
            <Step key={label}>
              <StepLabel className='mt-0' StepIconComponent={StepIcon} sx={{ marginTop: 0 }}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>

      <Divider />

      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {
          steps.map(({ label, Component }, idx) =>
            currentStep === idx && (
              <Component
                key={label}
                showFacebookIndicator={true}
                setLoadingNextButton={(payload: boolean) =>
                  setIsLoadingButton(payload)
                }
              />
            )
          )
        }
      </DialogContent>

      <Divider />

      <DialogActions>
        <Box
          display="flex"
          justifyContent={currentStep === 0 ? 'flex-end' : 'space-between'}
          width="100%"
        >
          {currentStep !== 0 &&
            <Button
              className="disabled:pointer-events-none"
              onClick={handlePreviousClick}
            >
              Previous
            </Button>
          }
          <Box>
            {
              currentUser?.onboarding?.is_complete && <Button
                className="disabled:pointer-events-none"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            }

            <LoadingButton
              variant="contained"
              onClick={handleNextClick}
              loading={isLoading || isloadingButton}
              disabled={disabledNextButton() || isloadingButton}
            >
              {isLastStep ? 'Finish' : 'Next'}
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
