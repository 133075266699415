import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid-pro"

import { BudgetCell } from 'components/dashboard/utils/BudgetCell';
import { CprCell } from 'components/dashboard/utils/CprCell';
import { ToggleStatusCell } from 'components/dashboard/utils/ToggleStatusCell';
import { AdAccounts } from "types";

type ColumnMappings = GridColDef & { displayName?: string }

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const columnMappings: { [key in string]: ColumnMappings } = {
  name: {
    field: 'name',
    headerName: 'Name',
    displayName: 'Name',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 2,
    headerAlign: 'left',
    align: 'left'
  },
  objective: {
    field: 'objective',
    headerName: 'Objective',
    displayName: 'Objective',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  status: {
    field: 'status',
    type: 'singleSelect',
    valueOptions: [
      'ACTIVE',
      'PAUSED',
      'ARCHIVED',
      'DELETED'
    ],
    headerName: 'Status',
    displayName: 'Status',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    renderCell: (cell: any) => <ToggleStatusCell cell={cell} />,
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cpr_risk: {
    field: 'cpr_risk',
    headerName: 'Risk',
    displayName: 'Cost Per Result Risk',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cost_per_result_target: {
    type: 'number',
    field: 'cost_per_result_target',
    headerName: 'Target',
    displayName: 'Cost Per Result Target',
    align: 'left',
    headerAlign: 'left',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    renderCell: (cell: any) => <CprCell cell={cell} />,
    flex: 1,
    minWidth: 100,
  },
  daily_budget: {
    type: 'number',
    field: 'daily_budget',
    headerName: 'Budget',
    displayName: 'Budget',
    align: 'left',
    headerAlign: 'left',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    renderCell: (cell: any) => <BudgetCell cell={cell} />,
    flex: 1,
    minWidth: 125,
  },
  spend: {
    type: 'number',
    field: 'spend',
    headerName: 'Spent',
    displayName: 'Spent',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    renderCell: ({ row }) => currencyFormatter.format(row.spend),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  tr_results: {
    type: 'number',
    field: 'trresults_count',
    headerName: 'TR Result',
    displayName: 'TR Result',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'center'
  },
  results: {
    type: 'number',
    field: 'results',
    headerName: 'Results',
    displayName: 'Results',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cpr: {
    type: 'number',
    field: 'cpr',
    headerName: 'CPR',
    displayName: 'Cost Per Result',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cpm: {
    type: 'number',
    field: 'cpm',
    headerName: 'CPM',
    displayName: 'Cost Per 1000 Impressions',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  impressions: {
    type: 'number',
    field: 'impressions',
    headerName: 'Impressions',
    displayName: 'Impressions',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  frequency: {
    type: 'number',
    field: 'frequency',
    headerName: 'Frequency',
    displayName: 'Frequency',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  reach: {
    type: 'number',
    field: 'reach',
    headerName: 'Reach',
    displayName: 'Reach',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cost_per_unique_click: {
    type: 'number',
    field: 'cost_per_unique_click',
    headerName: 'ULC',
    displayName: 'Unique Link Clicks',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cost_per_unique_inline_link_click: {
    type: 'number',
    field: 'cost_per_unique_inline_link_click',
    headerName: 'CPULC',
    displayName: 'Cost Per Unique Link Click',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  clicks: {
    type: 'number',
    field: 'clicks',
    headerName: 'Clicks',
    displayName: 'Clicks',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cpc: {
    type: 'number',
    field: 'cpc',
    headerName: 'CPC',
    displayName: 'Cost Per Click (All)',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  inline_link_clicks: {
    type: 'number',
    field: 'inline_link_clicks',
    headerName: 'Link Clicks',
    displayName: 'Link Clicks',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cost_per_inline_link_click: {
    type: 'number',
    field: 'cost_per_inline_link_click',
    headerName: 'CPLC',
    displayName: 'Cost Per Link Click',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  ccvr: {
    type: 'number',
    field: 'ccvr',
    headerName: 'CCVR',
    displayName: 'Copy Conversion Rate',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    renderCell: (cell: any) => renderPercentageCell(cell.row.ctr),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  cvr: {
    type: 'number',
    field: 'cvr',
    headerName: 'CVR',
    displayName: 'Conversion Rate',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    renderCell: (cell: any) => renderPercentageCell(cell.row.ctr),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  ctr: {
    type: 'number',
    field: 'ctr',
    headerName: 'CTR',
    displayName: 'Click Through Rate',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    renderCell: (cell: any) => renderPercentageCell(cell.row.ctr),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
  currency: {
    field: 'currency',
    headerName: 'Currency',
    displayName: 'Currency',
    renderHeader: ({ colDef }: any) => renderHeader(colDef),
    flex: 1,
    minWidth: 75,
    headerAlign: 'left',
    align: 'left'
  },
}

const renderHeader = ({
  displayName,
  headerName }: {
    displayName: string,
    headerName: string
  }) => (
  <Tooltip
    arrow
    disableFocusListener
    disableTouchListener
    placement="top-start"
    title={displayName}
  >
    <Typography variant="body2">{headerName}</Typography>
  </Tooltip>
);

const renderPercentageCell = (value: number) => <Typography variant="body2">{value}%</Typography>

export const getCprRiskCellClass = (risk: string) => {
  if (risk === 'HIGH') {
    return 'text-red-500';
  }

  if (risk === 'MEDIUM') {
    return 'text-orange-500';
  }

  return '';
}

export const getRowClassName = (row: AdAccounts): string => {
  let classes = row.hidden ? `bg-gray-200` : ''

  if (row.cpr_risk === 'HIGH') {
    classes += ' bg-red-500/10';
  }

  if (row.cpr_risk === 'MEDIUM') {
    classes += ' bg-orange-500/10';
  }

  if (!row.isVisible) {
    classes += ' hidden';
  }

  return classes
}

