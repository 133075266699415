import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";

import { useSetActiveFilterPreset, useGetFilterPresets } from "api/preferences";
import ActionFilterPreset from "./FilterPresets/ActionFilterPreset";
import { useDashboardContext } from "contexts/DashboardContext";

import MenuOptions from "./MenuOptions";
import { useSearchParams } from "react-router-dom";

const FilterPresets = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateFilterPresetModel, activeFilter } = useDashboardContext();

  const { data: filterPresets } = useGetFilterPresets({});

  const { mutate: updateActiveFilter } = useSetActiveFilterPreset({
    onMutate: async (filterPresetId: number) => {
      await queryClient.cancelQueries("filter-presets");
      const previousCurrentUserData =
        queryClient.getQueryData("filter-presets");

      queryClient.setQueryData("filter-presets", (oldQueryData: any) => {
        return oldQueryData?.map((preset: FilterPreset) => {
          if (preset.id !== filterPresetId) {
            return {
              ...preset,
              is_active: false,
            };
          }

          return {
            ...preset,
            is_active: true,
          };
        });
      });

      return previousCurrentUserData;
    },
    onError: (_error: any, _currentUser: number, context: any) => {
      queryClient.setQueryData(
        "filter-presets",
        context?.previousCurrentUserData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("filter-presets");
    },
  });

  const activeFilterPresetIndex = filterPresets?.findIndex(
    (preset) => preset.is_active
  );

  const activeFilterPreset = filterPresets?.find((item) => item.is_active);

  const handleMenuItemClick = (index: number) => {
    updateActiveFilter(index);
    setSelectedIndex(activeFilterPresetIndex ?? 0);
    updateFilterPresetModel({ items: activeFilterPreset?.filters });
    const stringQueryFilterPreset = JSON.stringify(activeFilterPreset?.filters);

    setSearchParams({
      ...searchParams,
      filters: stringQueryFilterPreset,
    });
  };

  useEffect(() => {
    setSelectedIndex(activeFilterPresetIndex ?? 0);
  }, [filterPresets]);

  return (
    <MenuOptions
      idName="filter-presets"
      presets={filterPresets}
      handleMenuItemClick={handleMenuItemClick}
      selectedIndex={selectedIndex}
      presetTitle="FILTER PRESETS"
    >
      <ActionFilterPreset />
    </MenuOptions>
  );
};

export default FilterPresets;
