import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FolderSpecialRoundedIcon from "@mui/icons-material/FolderSpecialRounded";

import { ColumnPreset } from "types";

type MenuProps = {
  idName: "column-presets" | "filter-presets" | undefined;
  selectedIndex: number;
  presets: FilterPreset[] | ColumnPreset[] | undefined;
  handleMenuItemClick: (index: number) => void;
  children?: React.ReactNode;
  presetTitle?: string;
};

export default function MenuOptions({
  idName,
  selectedIndex,
  presets,
  handleMenuItemClick,
  presetTitle,
  children,
}: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? idName : undefined;
  const itemMenuClickHandler = (id: number) => {
    handleMenuItemClick(id);
    setAnchorEl(null);
  };
  return (
    <>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": id,
          role: "listbox",
          sx: { width: 250 },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          {presets && presets?.length > 0
           ?presets?.map(({ name, id }, idx) => (
            <MenuItem
              key={id}
              onClick={() => itemMenuClickHandler(id)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ListItemText>{name}</ListItemText>
              {idx === selectedIndex && (
                <ListItemIcon sx={{ minWidth: "unset !important" }}>
                  <CheckRoundedIcon fontSize="small" color="primary" />
                </ListItemIcon>
              )}
            </MenuItem>
          ))
        : <MenuItem disabled><ListItemText>No presets</ListItemText></MenuItem>
        }
          <Divider />
          <nav aria-label="secondary mailbox folders">
            {children ? children : "No options available!"}
          </nav>
        </MenuList>
      </Menu>
      <Button onClick={handleClick} startIcon={<FolderSpecialRoundedIcon />}>
        {presetTitle ? presetTitle : "Presets"}
      </Button>
    </>
  );
}
