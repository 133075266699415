import Echo from "laravel-echo";
import Pusher from "pusher-js";
import axios from "axios";

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY ?? "", {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER ?? "",
});

export const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authorizer: (channel: any, options: any) => {
    return {
      authorize: (socketId: any, callback: any) => {
        axios
          .post(process.env.REACT_APP_BACKEND_URL + "/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(null, response.data);
          })
          .catch((error) => {
            callback(error);
          });
      },
    };
  },
});
