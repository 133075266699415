export const DASHBOARD_TABLE_COLUMNS = {
  DAILY_BUDGET: "daily_budget",
}

export const DEFAULT_COLUMN_VISIBILITY_MODEL = {
  daily_budget: true,
  objective: true,
  cost_per_result_target: true,
  cpr_risk: true,
  spend: true,
  tr_results: true,
  results: true,
  status: true,
  cpc: true,
  cpm: true,
  cpr: true,
  impressions: true,
  frequency: true,
  reach: true,
  cost_per_unique_click: true,
  cost_per_unique_inline_link_click: true,
  clicks: true,
  inline_link_clicks: true,
  cost_per_inline_link_click: true,
  ccvr: true,
  cvr: true,
  ctr: true,
  currency: true,
};
