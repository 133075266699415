import React, { useEffect, useState } from "react";
import { useGetColumnPresets } from "api";
import { ColumnPreset } from "types";

import { DeleteColumnPreset } from "./DeleteColumnPreset";
import { CreateColumnPreset } from "./CreateColumnPreset";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export const ActionColumnPreset = () => {
  const [activeColumnPreset, setActiveColumnPreset] = useState<ColumnPreset>();
  const { data: columnPresets } = useGetColumnPresets({
    onSuccess: () => {},
  });

  useEffect(() => {
    setActiveColumnPreset(
      columnPresets?.find(({ id, name, is_active }) => is_active)
    );
  }, [columnPresets]);

  return (
    <>
      <ListItem disablePadding>
        <CreateColumnPreset />
      </ListItem>
      <ListItem disablePadding>
        <DeleteColumnPreset activeColumnPreset={activeColumnPreset} />
      </ListItem>
    </>
  );
};
