import React, { createContext, useContext, useEffect } from "react";
import { ColumnPreset, DashboardContextInterface, SelectedRows } from "types";
import dayjs from "dayjs";
import { DEFAULT_COLUMN_VISIBILITY_MODEL } from "static/Column";
import { DEFAULT_FILTER_PRESET_MODEL } from "static/Filter";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { useGetColumnPresets } from "api";

type DashboardContextProviderProps = {
  children: React.ReactNode;
};

export const DashboardContext = createContext<DashboardContextInterface>({
  selectedRowsIds: [],
  selectedRows: {},
  showHiddenRows: false,
  lastSyncedTimestamp: "",
  columnVisibilityModel: {},
  changeColumnVisibilityModel: false,
  activeColumnPreset: undefined,
  columnPresets: [],
  filterPresetModel: DEFAULT_FILTER_PRESET_MODEL,
  isInvalidFilter: false,
  activeFilter: DEFAULT_FILTER_PRESET_MODEL,
  setChangeColumnVisibilityModel: (payload: boolean) => {},
  updateSelectedRows: (payload: any) => {},
  updateShowHiddenRows: (payload: boolean) => {},
  updateSelectedRowsIds: (payload: string[]) => {},
  updateLastSyncedTimestamp: (payload: string) => {},
  updateColumnVisibilityModel: (payload: string) => {},
  updateFilterPresetModel: (payload: FilterPreset) => {},
  updateActiveFilter: (payload: FilterPreset) => {},
});

export const DashboardContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  const [selectedRowsIds, setSelectedRowsIds] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<SelectedRows>({});
  const [showHiddenRows, setShowHiddenRows] = React.useState(false);
  const [lastSyncedTimestamp, setLastSyncedTimestamp] = React.useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>(DEFAULT_COLUMN_VISIBILITY_MODEL);
  const [changeColumnVisibilityModel, setChangeColumnVisibilityModel] =
    React.useState(false);
  const [activeColumnPreset, setActiveColumnPreset] =
    React.useState<ColumnPreset>();
  const [filterPresetModel, setFilterPresetModel] =
    React.useState<FilterPreset>(DEFAULT_FILTER_PRESET_MODEL);
  const [activeFilter, setActiveFilter] = React.useState<FilterPreset>(
    DEFAULT_FILTER_PRESET_MODEL
  );
  const [isInvalidFilter, setIsInvalidFilter] =
    React.useState<boolean>(false);

  const { data: columnPresets = [] } = useGetColumnPresets({
    onSuccess: () => {},
  });

  const updateSelectedRows = (payload: any) => {
    setSelectedRows(payload);
  };

  const updateShowHiddenRows = (payload: boolean) => {
    setShowHiddenRows(payload);
  };

  const updateSelectedRowsIds = (payload: string[]) => {
    setSelectedRowsIds(payload);
  };

  const updateLastSyncedTimestamp = (payload: string | null) => {
    if (!payload) return;

    const lastSyncedDate = dayjs(payload);
    setLastSyncedTimestamp(lastSyncedDate.format("YYYY/MM/DD hh:mm A"));
  };

  const updateColumnVisibilityModel = (payload: any) => {
    setColumnVisibilityModel(payload);
  };

  const updateFilterPresetModel = (payload: any) => {
    setFilterPresetModel(payload);
  };
  const updateActiveFilter = (payload: any) => {
    setActiveFilter(payload);
  };

  useEffect(() => {
    const activePreset = columnPresets?.find(
      ({ id, name, is_active }) => is_active
    );
    setActiveColumnPreset(activePreset);
   
  }, [columnPresets]);
 
  useEffect(() => {
    const filterImtersArr: GridFilterItem[] = filterPresetModel?.items
    const invalidFilter = filterImtersArr
      ?.map((item) => item)
      .some((item) => item.value === null || !item.value);
  
    if(invalidFilter){
      setIsInvalidFilter(true)
    } else {
      setIsInvalidFilter(false);
    }
   
  }, [filterPresetModel]);

  return (
    <DashboardContext.Provider
      value={{
        selectedRowsIds,
        selectedRows,
        showHiddenRows,
        lastSyncedTimestamp,
        columnVisibilityModel,
        changeColumnVisibilityModel,
        activeColumnPreset,
        columnPresets,
        filterPresetModel,
        activeFilter,
        isInvalidFilter,
        setChangeColumnVisibilityModel,
        updateSelectedRows,
        updateShowHiddenRows,
        updateSelectedRowsIds,
        updateLastSyncedTimestamp,
        updateColumnVisibilityModel,
        updateFilterPresetModel,
        updateActiveFilter,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
