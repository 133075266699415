import { useContext, useState } from "react";
import { useQueryClient } from "react-query";

import { useUpdateColumnVisibility } from "api";
import { useDashboardContext } from "contexts/DashboardContext";

import { ColumnPreset } from "types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useNotificationContext } from "contexts/Notification";

export const UpdateColumnPreset = () => {
  const { setNotification } = useNotificationContext()
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const {
    columnVisibilityModel,
    setChangeColumnVisibilityModel,
    activeColumnPreset,
  } = useDashboardContext();

  const { mutate: updateColumnVisibility, isLoading } =
    useUpdateColumnVisibility({
      onMutate: ({ column_preset, column_visibility }) => {
        const previousColumnPresetsData =
          queryClient.getQueryData("column-presets");

        queryClient.setQueryData("column-presets", (oldQueryData: any) => {
          return oldQueryData?.map((columnPreset: ColumnPreset) => {
            if (columnPreset.id === column_preset.id) {
              return {
                ...columnPreset,
                column_visibility: columnVisibilityModel,
              };
            }

            return columnPreset;
          });
        });

        return previousColumnPresetsData;
      },
      onSettled: () => {
        queryClient.invalidateQueries("column-presets");
        setOpen(false);
        setChangeColumnVisibilityModel(false);
        setNotification({
          open: true,
          message: "Update column preset successfully."
        })
      },
      onError: (_error: any, _payload: any, context: any) => {
        queryClient.setQueryData(
          "column-presets",
          context.previousColumnPresetsData
        );
      },
    });

  const handleClickUpdate = () => {
    if (!activeColumnPreset) return;

    updateColumnVisibility({
      column_preset: activeColumnPreset,
      column_visibility: columnVisibilityModel,
    });
  };
  return (
    <>
      <Button onClick={() => setOpen(true)} startIcon={<SaveIcon />}>
        Update preset
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Update preset: {activeColumnPreset?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to save column preset changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>No</Button>
          <LoadingButton
            loading={isLoading}
            onClick={handleClickUpdate}
            autoFocus
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
