import { useMutation, useQuery } from "react-query";
import axios from "axios";
import {
  SelectedRows,
  SetGetArgs,
  ColumnPreset,
  SetOptimisticUpdateGetArgs,
} from "types";

type GetSetProps = {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
};

export const useGetColumnPresets = ({
  onSuccess,
  onError,
  onSettled,
}: GetSetProps) => {
  return useQuery<ColumnPreset[]>({
    queryKey: "column-presets",
    queryFn: async () => {
      const response = await axios.get("/api/user/column-presets");

      if (response.status !== 200) {
        // TODO: Add some sort of monitoring/error logging like Sentry
        throw new Error("Unable to update CPR target and threshold");
      }

      return response.data.data;
    },
    onSuccess,
    onError,
    onSettled,
  });
};

export const useHideSelectedRows = ({ onSuccess, onError }: SetGetArgs) => {
  return useMutation({
    mutationFn: async ({
      ad_accounts,
      campaigns,
      adsets,
      ads,
    }: SelectedRows) => {
      const response = await axios.post(`/api/dashboard/hide-selected-rows`, {
        ad_accounts,
        campaigns,
        adsets,
        ads,
      });

      return response;
    },
    onSuccess,
    onError,
  });
};

export const useUpdateColumnVisibility = ({
  onSettled,
  onError,
  onMutate,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async ({
      column_preset,
      column_visibility,
    }: {
      column_preset: any;
      column_visibility: any;
    }) => {
      const response = await axios.put(
        `/api/user/column-presets/${column_preset.id}`,
        {
          column_visibility,
        }
      );

      return response;
    },
    onSettled,
    onError,
    onMutate,
  });
};

export const useSavePreset = ({
  onMutate,
  onError,
  onSettled,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async (columnPreset: any) => {
      const response = await axios.post(
        `/api/user/column-presets`,
        columnPreset
      );

      return response;
    },
    onMutate,
    onSettled,
    onError,
  });
};

export const useSetActivePreset = ({
  onMutate,
  onSettled,
  onError,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async (id: number) => {
      const response = await axios.put(`/api/user/column-presets/${id}/active`);

      return response;
    },
    onMutate,
    onSettled,
    onError,
  });
};

export const useDeleteActivePreset = ({
  onMutate,
  onSettled,
  onError,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async (columnPresetId: number) => {
      const response = await axios.delete(
        `/api/user/column-presets/${columnPresetId}`
      );

      return response;
    },
    onMutate,
    onSettled,
    onError,
  });
};

export const useDeleteColumnPresets = ({
  onMutate,
  onSettled,
  onError,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async (columnPresetsIds: number[]) => {
      const response = await axios.delete(
        "/api/user/column-presets/batch-delete",
        {
          data: {
            column_presets: columnPresetsIds,
          },
        }
      );

      return response;
    },
    onMutate,
    onSettled,
    onError,
  });
};

/*Filter Presets Methods Below */
export const useGetFilterPresets = ({
  onSuccess,
  onError,
  onSettled,
}: GetSetProps) => {
  return useQuery<FilterPreset[]>({
    queryKey: "filter-presets",
    queryFn: async () => {
      const response = await axios.get("/api/user/filter-presets");

      if (response.status !== 200) {
        // TODO: Add some sort of monitoring/error logging like Sentry
        throw new Error("Unable to update CPR target and threshold");
      }

      return response.data.data;
    },
    onSuccess,
    onError,
    onSettled,
  });
};

export const useSaveFilterPreset = ({
  onMutate,
  onError,
  onSettled,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async (filterPreset: any) => {
      const response = await axios.post(
        `/api/user/filter-presets`,
        filterPreset
      );

      return response;
    },
    onMutate,
    onSettled,
    onError,
  });
};

export const useDeleteFilterPreset = ({
  onMutate,
  onSettled,
  onError,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async (fitlerPresetId: number) => {
      const response = await axios.delete(
        `/api/user/filter-presets/${fitlerPresetId}`
      );

      return response;
    },
    onMutate,
    onSettled,
    onError,
  });
};

export const useSetActiveFilterPreset = ({
  onMutate,
  onSettled,
  onError,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async (id: number) => {
      const response = await axios.put(`/api/user/filter-presets/${id}/active`);

      return response;
    },
    onMutate,
    onSettled,
    onError,
  });
};

export const useUpdateFilterPreset = ({
  onSettled,
  onError,
  onMutate,
}: SetOptimisticUpdateGetArgs) => {
  return useMutation({
    mutationFn: async ({name,filters, id: filterPresetID}: FilterPreset) => {
      const response = await axios.put(
        `/api/user/filter-presets/${filterPresetID}`,
        {
          name,
          filters,
        }
      );
      return response;
    },
    onSettled,
    onError,
    onMutate,
  });
};

