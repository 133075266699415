import axios from "axios";
import { useMutation } from "react-query";
import { SetGetArgs } from "types";

export const useIntegrateAccount = ({ onSuccess }: SetGetArgs) => {
  return useMutation({
    mutationFn: (form: any) => {
      return axios.post(`/api/integrate/${form.provider}`, form);
    },
    onSuccess,
  });
};

export const useLoginViaFacebook = ({ onSuccess }: SetGetArgs) => {
  return useMutation({
    mutationFn: (form: any) => {
      return axios.post(`/api/login/${form.provider}`, form);
    },
    onSuccess,
  });
};

export const useSyncAccounts = ({ onSuccess }: SetGetArgs) => {
  return useMutation({
    mutationFn: () => {
      return axios.post(`/api/ad-accounts/sync`);
    },
    onSuccess,
  });
};
