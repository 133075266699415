import { useState } from "react";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { useSavePreset } from "api/preferences";
import { DEFAULT_COLUMN_VISIBILITY_MODEL } from "static/Column";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNotificationContext } from "contexts/Notification";

export const CreateColumnPreset = () => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext()
  const [open, setOpenDialog] = useState(false);
  const [presetName, setPresetName] = useState("");
  const [error, setError] = useState("");

  const { mutate: savePreset, isLoading } = useSavePreset({
    onMutate: async (columnPreset: any) => {
      await queryClient.cancelQueries("column-presets");
      const previousColumnPresetsData =
        queryClient.getQueriesData("column-presets");

      queryClient.setQueryData("column-presets", (oldQueryData: any) => {
        oldQueryData.push({
          id: uuidv4(),
          ...columnPreset,
        });

        return oldQueryData;
      });

      return previousColumnPresetsData;
    },
    onError: (_error: any, _columnPreset: any, context: any) => {
      queryClient.setQueryData(
        "column-presets",
        context.previousColumnPresetsData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("column-presets");
      setPresetName("");
      setError("");
      setOpenDialog(false);
      setNotification(prev => ({
        open: true,
        message: "Column Preset added successfully",
      }))
    },
  });

  const handleClickSave = () => {
    if (!presetName) {
      setError("Preset name is required.");
      return;
    }

    savePreset({
      name: presetName,
      column_visibility: DEFAULT_COLUMN_VISIBILITY_MODEL,
    });
  };


   const handleClose = () => {
      setOpenDialog(false)
      setError("");
      setPresetName("")
    };
  return (
    <>
      <ListItemButton onClick={() => setOpenDialog(true)}>
        <ListItemText primary="Create Preset" />
      </ListItemButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          {"Create New Column Preset"}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="standard-basic"
            label="Preset name"
            variant="standard"
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            helperText={error}
            error={error !== ""}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            onClick={handleClickSave}
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
