import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { useDashboardContext } from "contexts/DashboardContext";

import { useGetColumnPresets, useSetActivePreset } from "api/preferences";
import { ColumnPreset } from "types";

import MenuOptions from "./MenuOptions";
import { ActionColumnPreset } from "./ColumnPresets/ActionColumnPreset";


export const ColumnPresets = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const { setChangeColumnVisibilityModel } = useDashboardContext();

  const queryClient = useQueryClient();
  const { data: presets } = useGetColumnPresets({});

  const { mutate: updateActivePreset } = useSetActivePreset({
    onMutate: async (columnPresetId: number) => {
      await queryClient.cancelQueries("column-presets");
      const previousCurrentUserData =
        queryClient.getQueryData("column-presets");

      queryClient.setQueryData("column-presets", (oldQueryData: any) => {
        return oldQueryData?.map((preset: ColumnPreset) => {
          if (preset.id !== columnPresetId) {
            return {
              ...preset,
              is_active: false,
            };
          }

          return {
            ...preset,
            is_active: true,
          };
        });
      });

      return previousCurrentUserData;
    },
    onError: (_error: any, _currentUser: number, context: any) => {
      queryClient.setQueryData(
        "column-presets",
        context?.previousCurrentUserData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("column-presets");
    },
  });

  useEffect(() => {
    const activeColumnPresetIndex = presets?.findIndex(
      (preset) => preset.is_active
    );
    setSelectedIndex(activeColumnPresetIndex ?? 0);
  }, [presets]);

  const handleMenuItemClick = (index: number) => {
    const activeColumnPresetIndex = presets?.findIndex(
      (preset) => preset.id === index
    );
    setSelectedIndex(activeColumnPresetIndex ?? 0);
    updateActivePreset(index);
    setChangeColumnVisibilityModel(false);
  };

  return (
    <MenuOptions
      idName="column-presets"
      selectedIndex={selectedIndex}
      presets={presets}
      handleMenuItemClick={handleMenuItemClick}
      presetTitle="COLUMN PRESETS"
    >
      <ActionColumnPreset />
    </MenuOptions>
  );
};
