import React, { useState } from "react";
import { useDashboardContext } from "contexts/DashboardContext";
import { useSaveFilterPreset } from "api/preferences";
import { useQueryClient } from "react-query";
import { v4 as v4ID } from "uuid";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export const FilterPresetCreate = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [presetName, setPresetName] = useState<string>("");
  const [error, setError] = useState<string>("");
  
  const { isInvalidFilter, filterPresetModel } = useDashboardContext();

  const queryClient = useQueryClient();
  const { mutate: saveFilters, isLoading } = useSaveFilterPreset({
    onMutate: async (filterPreset: FilterPreset[]) => {
      await queryClient.cancelQueries("filter-presets");

      const previousFilterPresetsData =
        queryClient.getQueriesData("filter-presets");

      queryClient.setQueryData("filter-presets", (oldQueryData: any) => {
        oldQueryData.push({
          id: v4ID(),
          ...filterPreset,
        });

        return oldQueryData;
      });

      return previousFilterPresetsData;
    },
    onError: (_error: any, _filterPreset: any, context: any) => {
      queryClient.setQueryData(
        "filter-presets",
        context.previousFilterPresetsData
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("filter-presets");
      setPresetName("");
      setError("");
      setOpenDialog(false);
    },
  });
 
  const handleClose = () => {
    setOpenDialog(false)
    setError("")
  }
  const handleClickSave = async () => {
    if (!presetName) {
      setError("Preset name is required.");
      return;
    }

    if (isInvalidFilter) {
      setError("No filters selected or missing value on filters!");
      return;
    }

    const payload = {
      name: presetName,
      filters: filterPresetModel.items,
    };

    saveFilters(payload);
  };

  return (
    <>
      <Button onClick={() => setOpenDialog(true)} startIcon={<SaveIcon />}>
        Save as new preset
      </Button>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Create New Filter Preset
        </DialogTitle>
        <DialogContent>
          <TextField
            id="standard-basic"
            label="Preset name"
            variant="standard"
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            helperText={error}
            error={error !== ""}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            onClick={handleClickSave}
            autoFocus
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
