import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  DataGridPro,
  GridColDef,
  DataGridProProps,
  GridFilterModel,
  useGridApiRef,
  GridValidRowModel,
  GridRowId,
  GridColumnVisibilityModel,
  GridCallbackDetails,
} from "@mui/x-data-grid-pro";

import { DEFAULT_COLUMN_VISIBILITY_MODEL } from "static/Column";
import { DEFAULT_FILTER_PRESET_MODEL } from "static/Filter";

import { ErrorBoundary } from "components/ErrorBoundary";
import CustomToolBar from "./utils/CustomToolBar";
import {
  columnMappings,
  getCprRiskCellClass,
  getRowClassName,
} from "components/dashboard/utils/ColumnHelpers";
import { useAdAccountsInsightsQuery } from "api/adInsights";
import { useGetColumnPresets, useGetFilterPresets } from "api/preferences";
import { useDashboardContext } from "contexts/DashboardContext";
import { AdAccountInsightProps } from "types/AdAccount";

const FB_ACCOUNT_URL =
  "https://adsmanager.facebook.com/adsmanager/manage/campaigns";

const groupingColDef: DataGridProProps["groupingColDef"] = {
  headerName: "Name/ID",
  flex: 2,
  minWidth: 300,
  hideable: false,
  renderHeader: ({ colDef }: any) => (
    <Box display="flex" flexDirection="column" alignItems="flex-start" ml={5}>
      <Typography variant="body2" lineHeight={1}>
        Name
      </Typography>
      <Typography variant="caption" lineHeight={1}>
        ID
      </Typography>
    </Box>
  ),
  renderCell: (cell: any) => {
    const isExpanded = cell.rowNode.childrenExpanded;
    const handleExpandableButtonClick = () => {
      cell.api.setRowChildrenExpansion(cell.id, !isExpanded);
    };

    const depth = cell.rowNode.depth * 3 + 1;
    const cellId = cell.id.replace("act_", "");

    const renderNameAndId = () => {
      return (
        <Box display="flex" alignItems="center" gap="10px">
          {depth === 1 ? (
            <>
              <Box>
                <Link
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  href={`${FB_ACCOUNT_URL}?act=${cellId}`}
                  target="_blank"
                >
                  <Typography variant="body2" lineHeight={1}>
                    {cell.row.name}
                  </Typography>
                  <OpenInNewIcon color="primary" sx={{ fontSize: 13 }} />
                </Link>
                <Typography variant="caption" lineHeight={1}>
                  {cell.row.id}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="body2" lineHeight={1}>
                {cell.row.name}
              </Typography>
              <Typography variant="caption" lineHeight={1}>
                {cell.row.id}
              </Typography>
            </>
          )}
        </Box>
      );
    };

    return (
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label={isExpanded ? "collapse" : "expand"}
          size="small"
          sx={{
            height: 30,
            width: 30,
            visibility: cell?.rowNode?.children?.length ? "visible" : "hidden",
            marginLeft:
              cell?.rowNode?.depth > 0 ? `${20 * cell.rowNode.depth}px` : 0,
          }}
          onClick={handleExpandableButtonClick}
        >
          {isExpanded ? (
            <ExpandMoreRoundedIcon fontSize="inherit" />
          ) : (
            <ChevronRightRoundedIcon fontSize="inherit" />
          )}
        </IconButton>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          ml={depth}
          sx={{
            marginLeft: 1,
          }}
        >
          {renderNameAndId()}
        </Box>
      </Box>
    );
  },
};

const DATE_FORMAT = "YYYY-MM-DD";

export const DashboardTable = () => {
  const today = dayjs();
  const apiRef = useGridApiRef();
  const {
    activeFilter,
    filterPresetModel,
    selectedRowsIds,
    columnVisibilityModel,
    updateSelectedRowsIds,
    updateSelectedRows,
    updateLastSyncedTimestamp,
    updateColumnVisibilityModel,
    setChangeColumnVisibilityModel,
    updateFilterPresetModel,
    updateActiveFilter,
  } = useDashboardContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useAdAccountsInsightsQuery({
    search: searchParams.get("search"),
    start_date:
      searchParams.get("start_date") ??
      today.subtract(3, "day").format(DATE_FORMAT),
    end_date: searchParams.get("end_date") ?? today.format(DATE_FORMAT),
    show_hidden_rows: searchParams.get("show_hidden_rows"),
  });

  const { data: presets } = useGetColumnPresets({
    onSuccess: () => {},
  });
  const { data: filterPresets } = useGetFilterPresets({
    onSuccess: () => {},
  });

  const [filters, setFilters] = useState<GridFilterModel>({
    items: filterPresetModel.items,
  });
  const [adAccountInsights, setAdAccountInsight] = useState<
    AdAccountInsightProps[]
  >([]);
  const [initialAdAccounts, setInitialAdAccounts] = useState<
    AdAccountInsightProps[]
  >([]);

  useEffect(() => {
    if (data) {
      const { data: insights } = data;
      const _rows: AdAccountInsightProps[] = [];

      insights.forEach((adAccount: AdAccountInsightProps) => {
        const adAccountRow = {
          ...adAccount,
          path: [adAccount.id],
          id: adAccount.id,
          isVisible: true,
        };
        delete adAccountRow.campaigns;
        _rows.push(adAccountRow);

        if (!adAccount.campaigns) return;
        adAccount.campaigns.forEach((campaign: AdAccountInsightProps) => {
          const campaignRow = {
            ...campaign,
            path: [adAccount.id, campaign.id],
            isVisible: true,
          };
          delete campaignRow.adsets;
          _rows.push(campaignRow);

          if (!campaign.adsets) return;
          campaign.adsets.forEach((adset: AdAccountInsightProps) => {
            const adsetRow = {
              ...adset,
              path: [adAccount.id, campaign.id, adset.id],
              isVisible: true,
            };
            delete adsetRow.ads;
            _rows.push(adsetRow);

            if (!adset.ads) return;
            adset.ads.forEach((ad: AdAccountInsightProps) => {
              const adRow = {
                ...ad,
                path: [adAccount.id, campaign.id, adset.id, ad.id],
                isVisible: true,
              };
              _rows.push(adRow);
            });
          });
        });
      });

      if (adAccountInsights.length) {
        const parentWithExpandedChildren: AdAccountInsightProps[] = []
        _rows.forEach(row => {
          const rowNode = apiRef.current.getRowNode(row.id)
          rowNode && 'childrenExpanded' in rowNode && !!rowNode.childrenExpanded && parentWithExpandedChildren.push(row)
        })

        apiRef.current.updateRows(_rows);
        setAdAccountInsight(_rows);
        
        parentWithExpandedChildren.forEach(row => {
          const rowNode = apiRef.current.getRowNode(row.id)
          rowNode && 'childrenExpanded' in rowNode && apiRef.current.setRowChildrenExpansion(row.id, true)
        })
        return;
      }
      
      setInitialAdAccounts(_rows);
      setAdAccountInsight(_rows);
    }
  }, [data]);

  useEffect(() => {
    const currentFilter = filterPresets?.find((filter) => filter.is_active);
    updateFilterPresetModel({ items: currentFilter?.filters });
    setFilters({
      items: currentFilter?.filters || DEFAULT_FILTER_PRESET_MODEL.items,
    });
    updateActiveFilter(currentFilter || DEFAULT_FILTER_PRESET_MODEL);
  }, [filterPresets, activeFilter]);

  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current) {
      const filterParams = searchParams.get("filters");
      if (!filterParams) return;
      try {
        const parsedParams = JSON.parse("[" + filterParams + "]");
        if (parsedParams && parsedParams.length > 0) {
          setFilters((prevFilters) => ({
            items: [...prevFilters.items, ...parsedParams],
          }));
          updateFilterPresetModel({
            items: [...filters.items, ...parsedParams],
          });
        }
      } catch (error) {
        console.log("Invalid filters params");
      }
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  useEffect(() => {
    const activePreset = presets?.find((preset: any) => preset.is_active);

    updateColumnVisibilityModel(activePreset?.column_visibility ?? {});
  }, [presets]);

  const columns: GridColDef[] = useMemo(() => {
    const visibleCols: string[] = Object.entries(
      DEFAULT_COLUMN_VISIBILITY_MODEL
    )
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    return visibleCols.map((col) => columnMappings[col]);
  }, [presets]);

  useEffect(() => {
    if (data) {
      const { last_synced_timestamp } = data;
      updateLastSyncedTimestamp(last_synced_timestamp);
    }
  }, [data]);

  const slots = useMemo(
    () => ({
      toolbar: CustomToolBar,
      loadingOverlay: LinearProgress,
    }),
    []
  );

  const getTreeDataPath = useCallback(({ path }: any) => path, []);

  const handleRowSelectedModel = (newRowSelectionModel: any) => {
    const newSelectedRows = adAccountInsights.filter((row) =>
      newRowSelectionModel.includes(row.id)
    );

    updateSelectedRows({
      ad_accounts: getRowId(newSelectedRows, 1),
      campaigns: getRowId(newSelectedRows, 2),
      adsets: getRowId(newSelectedRows, 3),
      ads: getRowId(newSelectedRows, 4),
    });

    updateSelectedRowsIds(newRowSelectionModel);
  };

  const getRowId = (selectedRows: any[], pathIndex: number) => {
    return selectedRows
      .filter((row) => row.path.length === pathIndex)
      .map((row) => row.id);
  };

  const handleFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    // setQueryOptions({ filterModel: { ...filterModel } });
    const filters = filterModel.items
      ?.filter((item) => item.value)
      .map(
        (item) =>
          `{"field":"${item.field}","operator":"${item.operator}","value":"${item.value}","id":"${item.id}"}`
      );
    const filterStatus = filterModel.items.find(
      (item) => item.field === "status"
    );
    setFilters({
      items: filterModel.items,
      logicOperator: filterModel?.logicOperator,
    });
    updateFilterPresetModel({ items: filterModel.items });

    setSearchParams({
      ...searchParams,
      filters: filters.join(","),
    });

    const rowModels = apiRef.current.getRowModels();
    let newRows: GridValidRowModel[] = [];
    if (filterStatus && filterStatus.value) {
      rowModels.forEach((row: GridValidRowModel, key: GridRowId): void => {
        let isVisible = false;

        switch (filterStatus.operator) {
          case "is":
            isVisible = row.status === filterStatus.value;
            break;
          case "not":
            isVisible = row.status !== filterStatus.value;
            break;
          default:
            isVisible =
              filterStatus.value.length > 0
                ? filterStatus.value.includes(row.status)
                : true;
        }

        newRows.push({
          ...row,
          isVisible,
        });
      });
    } else {
      rowModels.forEach((row: GridValidRowModel, key: GridRowId): void => {
        newRows.push({
          ...row,
          isVisible: true,
        });
      });
    }

    apiRef.current.setRows(newRows);
  }, []);

  const handleColumnVisibilityChange = (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails
  ): void => {
    const newModel = Object.entries(model);
    if (newModel.length <= 1) {
      updateColumnVisibilityModel(DEFAULT_COLUMN_VISIBILITY_MODEL);
    } else {
      updateColumnVisibilityModel({
        ...columnVisibilityModel,
        ...model,
      });
    }

    setChangeColumnVisibilityModel(true);
  };

  return (
    <ErrorBoundary fallback={<p>There was an issue rendering your accounts</p>}>
      <Paper elevation={2} sx={{ flex: 1, padding: 2 }}>
        <DataGridPro
          treeData
          apiRef={apiRef}
          getTreeDataPath={getTreeDataPath}
          columns={columns}
          rows={initialAdAccounts}
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          hideFooterRowCount
          autoPageSize
          groupingColDef={groupingColDef}
          slots={slots}
          // We'd want to grab the user's preferences for the model and pass it into this.
          /// This would be where we establish presets as well
          columnVisibilityModel={columnVisibilityModel}
          getCellClassName={({ row }) => getCprRiskCellClass(row.cpr_risk)} // How we can define color status
          onColumnOrderChange={(...args) => console.log(args)}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          /**
           * Some props we might want to use in the future
           */
          // disableColumnFilter
          // disableColumnMenu
          // disableColumnSelector
          onRowSelectionModelChange={handleRowSelectedModel}
          rowSelectionModel={selectedRowsIds}
          getRowClassName={(params) => getRowClassName(params.row)}
          // filterMode='server'
          filterModel={filters}
          onFilterModelChange={handleFilterChange}
          pinnedColumns={{ left: ["__check__", "__tree_data_group__"] }}
          // defaultGroupingExpansionDepth={-1} "Expand All Button"?
          // rowSelectionModel={selectedAccounts.map(({ id }) => id)} // Good for setting preselected rows
          // onColumnResize
          // onColumnWidthChange
          // onPreferencePanelClose={(...args) => console.log(args)}
          // onPreferencePanelOpen={(...args) => console.log(args)}
          // onColumnHeaderClick={(...args) => console.log(args)}
          // onColumnVisibilityModelChange={(...args) => console.log('changed column visibility', args)}
        />
      </Paper>
    </ErrorBoundary>
  );
};
