import { useEffect } from 'react';
import { Outlet, useSearchParams, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from 'react-auth-kit';
import Box from '@mui/material/Box';

import { Navbar } from './Navbar';
import { Onboarding } from 'views/Onboarding';
import { OnboardingProvider } from 'contexts/OnboardingContext';
import { useCurrentUser } from 'contexts/CurrentUser';
import { ErrorBoundary } from 'components/ErrorBoundary';

export const AuthenticatedLayout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser && !currentUser?.onboarding?.is_complete) {
      searchParams.append('onboarding', 'true');
      setSearchParams(searchParams);
    }

    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [currentUser]);

  return (
    <>
      <Box
        display="flex"
        height="100vh"
        marginLeft="65px" // Accounts for Navbar minimized width
      >
        <Navbar />
        <Box flex="1" className="bg-gray-100" p={2} maxWidth="100%">
          <ErrorBoundary fallback={<p>Something went wrong in authenticated layouts</p>}>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
      <OnboardingProvider>
        <Onboarding
          open={ !!searchParams.get('onboarding') }
          onClose={ () => {
            searchParams.delete('onboarding');
            setSearchParams(searchParams);
          }}
        />
      </OnboardingProvider>
    </>
  )
}
