import React, { useState } from "react";
import { useQueryClient } from "react-query";

import { useDeleteActivePreset } from "api";

import { ColumnPreset } from "types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNotificationContext } from "contexts/Notification";

export const DeleteColumnPreset = ({
  activeColumnPreset,
}: {
  activeColumnPreset: ColumnPreset | undefined;
}) => {
  const { setNotification } = useNotificationContext()
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutate: deleteActivePreset, isLoading } = useDeleteActivePreset({
    onMutate: async (columnPresetId: number) => {
      await queryClient.cancelQueries("column-presets");
      const previousColumnPresets = queryClient.getQueryData("column-presets");

      queryClient.setQueryData("column-presets", (oldQueryData: any) => {
        const newColumnPresets = oldQueryData
          ?.filter(({ id, name }: ColumnPreset) => id !== columnPresetId)
          .map((columnPreset: ColumnPreset) => {
            if (columnPreset.name === "Default") {
              return {
                ...columnPreset,
                is_active: true,
              };
            }

            return columnPreset;
          });

        return newColumnPresets;
      });

      return previousColumnPresets;
    },
    onError: (_error: any, _columnPresetId: any, context: any) => {
      queryClient.setQueryData(
        "column-presets",
        context?.previousColumnPresets
      );
    },
    onSettled: () => {
      setOpen(false);
      setNotification({
        open: true,
        message: "Column preset deleted successfully",
      })
    },
  });

  const handleDeletePreset = () => {
    if (!isAllowedToDeleteActivePreset) return;

    deleteActivePreset(activeColumnPreset.id);
  };

  const isAllowedToDeleteActivePreset =
    activeColumnPreset && activeColumnPreset.name !== "Default";

  return (
    <>
      <ListItemButton
        disabled={!isAllowedToDeleteActivePreset}
        onClick={() => setOpen(true)}
      >
        <ListItemText primary="Delete Preset" />
      </ListItemButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Preset: {activeColumnPreset?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to continue deleting this preset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>No</Button>
          <LoadingButton
            loading={isLoading}
            onClick={handleDeletePreset}
            autoFocus
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
