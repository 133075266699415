import React from "react";
import { CreateColumnPreset } from "./ColumnPresetCreate";
import { UpdateColumnPreset } from "./ColumnPresetUpdate";

const ColumnPresetActions = () => {
  return (
    <>
      <CreateColumnPreset />
      <UpdateColumnPreset />
    </>
  );
};

export default ColumnPresetActions;
