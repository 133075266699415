import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import { useUpdateFilterPreset } from "api/preferences";
import { useDashboardContext } from "contexts/DashboardContext";

export const FilterPresetUpdate = () => {
  const [open, setOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { filterPresetModel, activeFilter, isInvalidFilter } =
    useDashboardContext();

  const { mutate: updateFilterPreset, isLoading } = useUpdateFilterPreset({
    onMutate: (filter_preset: FilterPreset) => {
      const previousFilterPresetsData =
        queryClient.getQueryData("filter-presets");

      queryClient.setQueryData("filter-presets", (oldQueryData: any) => {
        return oldQueryData?.map((filterPreset: FilterPreset) => {
          if (filterPreset.id === filter_preset.id) {
            return {
              ...filterPreset,
              filters: filterPresetModel?.items,
            };
          }
          return filterPreset;
        });
      });

      return previousFilterPresetsData;
    },
    onSettled: () => {
      queryClient.invalidateQueries("filter-presets");
      setOpen(false);
    },
    onError: (_error: any, _payload: any, context: any) => {
      queryClient.setQueryData(
        "filter-presets",
        context.previousFilterPresetsData
      );
    },
  });

  const handleClickUpdate = () => {
    if (!activeFilter) return;
   
    updateFilterPreset({ ...activeFilter, filters: filterPresetModel.items});
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} startIcon={<SaveIcon />}>
        Update preset
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Update preset: {activeFilter?.name}
        </DialogTitle>
        {isInvalidFilter ? (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{ fontWeight: "500", color: "red"}}>
                No filters selected or missing values, please review filters!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Ok. review filters</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to save filter changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>No</Button>
              <LoadingButton
                loading={isLoading}
                onClick={handleClickUpdate}
                autoFocus
              >
                Yes
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
