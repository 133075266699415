import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { LicenseInfo } from "@mui/x-license-pro";
import axios from "axios";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "react-auth-kit";

import { CurrentUserProvider } from "contexts/CurrentUser";

import { CustomThemeProvider } from "contexts/CustomTheme";
import { AuthenticatedLayout } from "layouts/Authenticated";
import { AuthFormLayout } from "layouts/AuthForm";
import { Dashboard } from "views/Dashboard";
import { Settings } from "views/Settings";
import { Tracking } from "views/Tracking";
import { Profile } from "views/Profile";
import { Signup } from "views/Signup";
import { Login } from "views/Login";
import { ForgotPassword } from "views/ForgotPassword";
import { ResetPassword } from "views/ResetPassword";
import { EmailVerification } from "views/EmailVerification";
import { NotificationProvider } from "contexts/Notification";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY as string);

export const App = () => {
  axios.interceptors.request.use((config) => {
    // TODO: Grab token from cookies instead.
    const token =
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("_auth="))
        ?.split("=")[1] ?? "";

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "local") {
      config.baseURL = process.env.REACT_APP_BACKEND_URL;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (
        err.response.status === 401 &&
        window.location.pathname !== "/login"
      ) {
        window.location.href = window.location.origin + "/login";
      }

      return Promise.reject(err);
    }
  );

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        authType="cookie"
        authName="_auth"
        cookieDomain={window.location.hostname}
        cookieSecure={process.env.NODE_ENV === "production"}
      >
        <NotificationProvider>
          <CookiesProvider>
            <CustomThemeProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <CurrentUserProvider>
                        <AuthenticatedLayout />
                      </CurrentUserProvider>
                    }
                  >
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/tracking" element={<Tracking />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/profile" element={<Profile />} />
                  </Route>
                  <Route
                    path="/signup"
                    element={
                      <AuthFormLayout>
                        <Signup />
                      </AuthFormLayout>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <AuthFormLayout>
                        <Login />
                      </AuthFormLayout>
                    }
                  />
                  <Route
                    path="/forgotpassword"
                    element={
                      <AuthFormLayout>
                        <ForgotPassword />
                      </AuthFormLayout>
                    }
                  />
                  <Route
                    path="/reset-password"
                    element={
                      <AuthFormLayout>
                        <ResetPassword />
                      </AuthFormLayout>
                    }
                  />
                  <Route
                    path="/email-verification"
                    element={
                      <AuthFormLayout>
                        <EmailVerification />
                      </AuthFormLayout>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </CustomThemeProvider>
          </CookiesProvider>
        </NotificationProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};
