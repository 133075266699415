import { useQuery, useMutation } from "react-query";
import axios from "axios";

import type { AdAccounts, SetGetArgs } from "types";

// const MOCK_ACCOUNTS: AdAccounts[] = [
//   {
//     name: 'Baoss Digital',
//     id: 'act_125489374521',
//   },
//   {
//     name: 'Bao Le',
//     id: 'act_485793847598',
//   },
//   {
//     name: 'Real Estate Lead Gen Campaigns',
//     id: 'act_278349283743',
//   },
//   {
//     name: 'Belvedere Engagement',
//     id: 'act_394802385739',
//   },
//   {
//     name: 'Baoss Digital - FAAB',
//     id: 'act_493085948305',
//   },
//   {
//     name: 'Baoss Digital',
//     id: 'act_125489374321',
//   },
//   {
//     name: 'Bao Le',
//     id: 'act_485793547598',
//   },
//   {
//     name: 'Real Estate Lead Gen Campaigns',
//     id: 'act_278329283743',
//   },
//   {
//     name: 'Belvedere Engagement',
//     id: 'act_394802185739',
//   },
//   {
//     name: 'Baoss Digital - FAAB',
//     id: 'act_493085938305',
//   },
// ];

export const useAdAccountsQuery = () => {
  return useQuery<{ data: AdAccounts[] }>({
    queryKey: "ad-accounts",
    staleTime: 3600,
    queryFn: async () => {
      // return new Promise<AdAccounts[]>((resolve) => resolve(MOCK_ACCOUNTS));

      const response = await axios.get("/api/ad-accounts");

      if (response.status !== 200) {
        // TODO: Add some sort of monitoring/error logging like Sentry
        throw new Error("Unable to fetch ad accounts");
      }

      return response.data;
    },
  });
};

export const useSelectedAdAccountQuery = () => {
  return useQuery<{ data: AdAccounts[] }>({
    queryKey: "selected-ad-accounts",
    staleTime: 5 * 60 * 1000,
    queryFn: async () => {
      const response = await axios.get(`/api/ad-accounts/selected`);

      if (response.status !== 200) {
        throw new Error("Unable to fetch seelcted ad accounts");
      }

      return response.data;
    },
  });
};

export const useAdAccountsInsightsQuery = ({
  search,
  start_date,
  end_date,
  show_hidden_rows,
}: {
  search?: string | null;
  start_date?: string | null;
  end_date?: string | null;
  show_hidden_rows?: string | null;
}) => {
  return useQuery<any>({
    queryKey: [
      "ad-account-insights",
      search,
      start_date,
      end_date,
      show_hidden_rows,
    ],
    staleTime: 3600,
    queryFn: async () => {
      const params = new URLSearchParams();
      if (search) {
        params.append("search", search);
      }

      if (start_date) {
        params.append("start_date", start_date);
      }

      if (end_date) {
        params.append("end_date", end_date);
      }

      if (show_hidden_rows) {
        params.append("show_hidden_rows", show_hidden_rows);
      }

      const response = await axios.get(
        `/api/dashboard/ad-accounts?${params.toString()}`
      );

      if (response.status !== 200) {
        // TODO: Add some sort of monitoring/error logging like Sentry
        throw new Error("Unable to fetch ad accounts");
      }

      return response.data;
    },
  });
};

export const useManualSyncInsightsMutation = ({ onSuccess }: SetGetArgs) => {
  return useMutation({
    mutationFn: () => {
      return axios.get(`/api/user/insights/sync`);
    },
    onSuccess,
  });
};
