import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useSelectedAdAccountQuery,
  generateTracking,
  getDomains,
} from "api/tracking";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//import hljs from "highlight.js";

type AdAccounts = {
  id: string;
  name: string;
};

export const AddAccounts = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Fetch data using the useSelectedAdAccountQuery hook
  const { data: { data: result = [] } = {}, refetch } =
    useSelectedAdAccountQuery();
  const [validationError, setValidationError] = React.useState<string | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [selectedAccountId, setSelectedAccountId] = React.useState("");
  const [selectedAccountName, setSelectedAccountName] = React.useState("");
  const [domains, setDomains] = React.useState("");
  const [scriptCode, setScriptCode] = React.useState<string | null>(null);
  useEffect(() => {
    refetch();
  }, [searchParams]);

  const handleClickOpen = async (id: string, name: string) => {
    setScriptCode(null);
    setSelectedAccountId(id);
    setSelectedAccountName(name);
    setOpen(true);
    try {
      const domainsData = await getDomains(id);
      console.log(domainsData);
      if (domainsData.domains && domainsData.domains.length > 0) {
        const domainString = domainsData.domains.join(", ");

        setDomains(domainString);
      } else {
        setDomains(""); // If there are no domains, set an empty string or an appropriate default value
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleClose = () => {
    setScriptCode(null);
    setDomains("");
    setSelectedAccountId("");
    setSelectedAccountName("");
    setOpen(false);
  };

  const handleValidate = async () => {
    if (!domains) {
      setValidationError("Please enter domains before validating.");
      return;
    }

    try {
      setValidationError(null); // Clear any previous validation errors
      const trackingData = await generateTracking(selectedAccountId, domains);

      if (trackingData) {
        const generatedScriptCode = `<script>
          (function(){
            var s=document.createElement('script');
            s.src='${process.env.REACT_APP_TRACKING_APP_URL}/track/sam_track.js';
            s.async=true;
            s.onload=function(){
              sam_track('${trackingData}');
            };
            document.head.appendChild(s);
          })();
        </script>`;
        setScriptCode(generatedScriptCode);
      }
      /*if (trackingData) {
        const generatedScriptCode = `<script src="${process.env.REACT_APP_TRACKING_APP_URL}/track/sam_track.js"></script>\n<script>\n    sam_track('${trackingData}');\n</script>`;
        setScriptCode(generatedScriptCode);
      }*/

      console.log("Tracking data:", trackingData);
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  return (
    <div>
      <List className="w-full">
        {result.map(({ name, id }: AdAccounts) => (
          <ListItem
            key={id}
            sx={{
              padding: 0,
              marginBottom: 2,
              width: "100%",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
                padding: 1,
                width: "100%",
              }}
            >
              <Box display="flex">
                <p className="self-center">
                  {name} ({id})
                </p>
              </Box>
              <Box display="flex" gap={1}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleClickOpen(id, name)}
                >
                  Setup Tracking Code
                </Button>
              </Box>
            </Paper>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{ style: { minWidth: 600, maxWidth: 700 } }}
      >
        <DialogTitle>
          Tracking Code: <b>{selectedAccountName}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add your domains separated with comma where you want to activate the
            tracking script, then validate and copy/past your tracking code
            inside head of your site.
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="domain"
            label="Domains ex: domain1.com, domain2.com..."
            type="text"
            fullWidth
            variant="standard"
            value={domains}
            onChange={(e) => setDomains(e.target.value)}
            error={Boolean(validationError)}
            helperText={validationError}
          />
          <TextField id="account_id" value={selectedAccountId} type="hidden" />
          <Button onClick={handleValidate} variant="contained">
            Validate
          </Button>
          {scriptCode && (
            <div>
              <DialogContentText>
                Copy and paste the following script code:
              </DialogContentText>
              <pre>{scriptCode}</pre>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
