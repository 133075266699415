import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { TrackingSearch } from "components/tracking/TrackingSearch";
import { AddAccounts } from "components/tracking/AddAccounts";

export const Tracking = () => {
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Typography variant="h5" mb={1}>
        Tracking
      </Typography>
      <TrackingSearch />
      <AddAccounts />
    </Box>
  );
};
