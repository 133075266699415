

import ListItem from "@mui/material/ListItem";

import CreateFilterPreset from "./CreateFilterPreset";
import DeleteFilterPreset from "./DeleteFilterPreset";

const ActionFilterPreset = () => {
  return (
    <>
      <ListItem disablePadding>
        <CreateFilterPreset />
        <DeleteFilterPreset />
      </ListItem>
    </>
  );
};

export default ActionFilterPreset;