import Box from "@mui/material/Box";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";
import SyncIcon from "@mui/icons-material/Sync";
import Typography from "@mui/material/Typography";

import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import { ColumnPresets } from "components/dashboard/utils/ColumnPresets";
import FilterPresets from "components/dashboard/utils/FilterPresets";
import { useDashboardContext } from "contexts/DashboardContext";
import { useHideSelectedRows, useManualSyncInsightsMutation } from "api";
import { echo } from "pusher";
import { useAuthUser } from "react-auth-kit";
import { useCurrentUser } from "contexts/CurrentUser";
import ColumnPresetActions from "./ColumnPresetActions";
import FilterPresetActions from "./FilterPresetActions/FilterPresetActions";
import { filterQueryParser } from "utils/filterQueryParser";
import { useNotificationContext } from "contexts/Notification";

const CustomToolBar = () => {
  const queryClient = useQueryClient();
  const auth = useAuthUser();
  const {
    selectedRows,
    filterPresetModel,
    activeFilter,
    changeColumnVisibilityModel,
    updateSelectedRowsIds,
  } = useDashboardContext();
  const { setNotification } = useNotificationContext()
  const [searchParams, setSearchParams] = useSearchParams();
  const [showHiddenRows, setShowHiddenRows] = useState<boolean>(
    searchParams.get("show_hidden_rows") ? true : false
  );
  const { mutate: handleHideRows } = useHideSelectedRows({
    onSuccess: () => {
      queryClient.invalidateQueries("ad-account-insights");
      updateSelectedRowsIds([]);
      setNotification({
        open: true,
        message: "Success."
      })
    },
  });
  const { currentUser } = useCurrentUser();

  const { mutate: syncAccounts, isLoading } = useManualSyncInsightsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries("ad-account-insights");
    },
  });

  const handleClickHideRows = () => {
    handleHideRows(selectedRows);
  };

  useEffect(() => {
    const newChannel = echo.private(`users.${auth()?.id}.ad-accounts.syncing`);
    newChannel.listen(".ad-account.sync.notification", (response: any) => {
      if (response?.synced) {
        queryClient.invalidateQueries("ad-account-insights");
        queryClient.invalidateQueries("me");
      }
    });
  }, []);

  const isValidSelectedRow: boolean = useMemo(() => {
    if (
      selectedRows?.ad_accounts?.length === 0 &&
      selectedRows?.campaigns?.length === 0 &&
      selectedRows?.adsets?.length === 0 &&
      selectedRows?.ads?.length === 0
    )
      return false;

    return true;
  }, [selectedRows]);

  const handleChangeVisibility = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (!checked) {
      searchParams.delete("show_hidden_rows");
      setSearchParams(searchParams);
    } else {
      setSearchParams(prev => ([...prev.entries(), ['show_hidden_rows', 'true']]));
    }

    setShowHiddenRows(event.target.checked);
  };

  const handleManualSync = () => {
    if (isLoading) return;

    syncAccounts();
  };

  const filterModelChanges = useMemo(() => {
    if (
      filterQueryParser(filterPresetModel?.items) !==
      filterQueryParser(activeFilter?.filters as GridFilterItem[])
    ) {
      return true;
    } else {
      return false;
    }
  }, [filterPresetModel, activeFilter]);

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        button: {
          lineHeight: 1,
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" gap={1} alignItems="center">
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          {isValidSelectedRow && (
            <LoadingButton onClick={handleClickHideRows}>
              Show/Hide Rows
            </LoadingButton>
          )}
          <Tooltip title={showHiddenRows ? "Hide Rows" : "Show Rows"}>
            <Checkbox
              icon={<VisibilityOffIcon />}
              checkedIcon={<VisibilityIcon />}
              onChange={handleChangeVisibility}
              checked={showHiddenRows}
            />
          </Tooltip>
        </Box>
        <Box display="flex" alignContent="center" gap="10px">
          <Tooltip
            title={
              currentUser?.sync?.status ? "Syncing Accounts" : "Sync Accounts"
            }
          >
            <Box display="flex" alignItems="center" marginRight="10px">
              {currentUser?.sync?.status === "SYNCING" && (
                <>
                  <SyncIcon
                    sx={[
                      { marginRight: "10px" },
                      currentUser?.sync?.status === "SYNCING"
                        ? {
                            animation: "spin 2s linear infinite",
                            "@keyframes spin": {
                              "0%": {
                                transform: "rotate(360deg)",
                              },
                              "100%": {
                                transform: "rotate(0deg)",
                              },
                            },
                          }
                        : {},
                    ]}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Typography
                      margin="0"
                      fontSize="12px"
                      display="block"
                      gutterBottom
                    >
                      {currentUser?.sync?.status === "SYNCING"
                        ? "Syncing Accounts..."
                        : "Sync Accounts"}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Tooltip>
          <FilterPresets />
          {filterModelChanges && <FilterPresetActions />}
          {changeColumnVisibilityModel && <ColumnPresetActions />}
          <ColumnPresets />
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export default CustomToolBar;
