import React, { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { useDashboardContext } from "contexts/DashboardContext";

import { useDeleteFilterPreset} from "api/preferences";

export default function DeleteFilterPreset() {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { activeFilter } = useDashboardContext();

  const { mutate: deleteActiveFilter, isLoading } = useDeleteFilterPreset({
    onMutate: async (filterPresetId: number) => {
      await queryClient.cancelQueries("filter-presets");
      const previousFilterPresets = queryClient.getQueryData("filter-presets");

      queryClient.setQueryData("filter-presets", (oldQueryData: any) => {
        const newFilterPresets = oldQueryData
          ?.filter(({ id, name }: FilterPreset) => id !== filterPresetId)
          .map((filterPreset: FilterPreset) => {
            if (filterPreset.name === "Default") {
              return {
                ...filterPreset,
                is_active: true,
              };
            }

            return filterPreset;
          });

        return newFilterPresets;
      });

      return previousFilterPresets;
    },
    onError: (_error: any, _filterPresetId: any, context: any) => {
      queryClient.setQueryData(
        "filter-presets",
        context?.previousFilterPresets
      );
    },
    onSettled: () => {
      setOpen(false);
    },
  });

  const isAllowedToDeleteActivePreset = activeFilter && activeFilter.name !== "default";

  const deleteFilter = () => {
   if(!isAllowedToDeleteActivePreset) return;
    
    deleteActiveFilter(activeFilter.id);
  }

   return (
     <>
       <ListItemButton
         disabled={!isAllowedToDeleteActivePreset}
         onClick={() => setOpen(true)}
       >
         <ListItemText primary="Delete Preset" />
       </ListItemButton>

       <Dialog
         open={open}
         onClose={() => setOpen(false)}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         fullWidth
         maxWidth="xs"
       >
         <DialogTitle id="alert-dialog-title">
           Delete Preset: {activeFilter.name}
         </DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-description">
             Do you want to delete this filter?
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button onClick={() => setOpen(false)}>No</Button>
           <LoadingButton loading={isLoading} onClick={deleteFilter} autoFocus>
             Yes
           </LoadingButton>
         </DialogActions>
       </Dialog>
     </>
   );
}
